import React from 'react';
import { Link } from 'gatsby';
import urlProcess from '../utils/url-process';

import '../styles/categories-list.scss';

const CategoriesList = ({ categories }) => (
  <ul className="categories-list">
    {categories.map((category) => (
      <li key={`/categories/${urlProcess(category)}/`}>
        <small>
          <Link to={`/categories/${urlProcess(category)}/`}>{category}</Link>
        </small>
      </li>
    ))}
  </ul>
);

export default CategoriesList;
