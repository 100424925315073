import React from 'react';
import { Link } from 'gatsby';
import '../styles/pager.scss';

const Pager = ({ currentPage, numPages }) =>
  numPages ? (
    <div className="pagination">
      {Array.from({ length: numPages }, (_, i) => (
        <Link
          key={`pagination-number${i + 1}`}
          to={`/blog/${i === 0 ? '' : i + 1}`}
          className={(currentPage || 1) - 1 === i ? 'active' : ''}
        >
          {i + 1}
        </Link>
      ))}
    </div>
  ) : null;

export default Pager;
