import React from 'react';
import '../styles/date-categories.scss';

import CategoriesList from './categories-list';
import formatDate from '../utils/format-date';

const DateCategories = ({ data }) => (
  <div className="date-cat-wrapper">
    <CategoriesList categories={data.categories} />
    <time dateTime={data.date}>{formatDate(data.date)}</time>
  </div>
);

export default DateCategories;
