import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { graphql, Link } from 'gatsby';
import Layout from '../components/layout';
import BodyDrawer from '../components/body-drawer';
import Pager from '../components/pager';

import PostPreview from '../components/post-preview';

const Index = ({ data }) => {
  const posts = data.allMarkdownRemark.edges;
  const numPages = Math.ceil(data.allMarkdownRemark.totalCount / posts.length);
  const metaData = {
    title: 'My personal blog',
    excerpt:
      'Hello, my name is Pantaley and I’m a senior software engineer. Between my personal and professional life I try to find time and share what I’ve learned.'
  };

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
      </Helmet>
      <Layout postData={metaData}>
        <p>
          Hello, my name is Pantaley and I’m a senior software engineer. Between my personal and professional life I try
          to find time and share what I’ve learned. If you have some feedback or proposal, you can always find me on{' '}
          <a href="https://www.linkedin.com/in/pantaley/" target="_blank" rel="noopener noreferrer">
            LinkedIn
          </a>
          , the <Link to="/contacts">contact form</Link> or through comments in the <Link to="/blog">articles</Link>.
        </p>
        <h2>Latest articles</h2>
        <BodyDrawer>
          {posts.map(({ node }) => (
            <PostPreview key={node.id} data={node} />
          ))}
        </BodyDrawer>
        <Pager numPages={numPages} />
      </Layout>
    </Fragment>
  );
};

export default Index;

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }, limit: 8) {
      ...markdownRemarkEdge
    }
  }
`;
