import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import blogPostURLProcess from '../utils/blog-post-url-process';
import '../styles/post-preview.scss';

import DateCategories from './date-categories';

const PostPreview = ({ data }) => (
  <article className="post-preview">
    <Link
      className="link-wrapper"
      to={blogPostURLProcess(data.fields.slug)}
      aria-label={`Read more about ${data.frontmatter.title}`}
    >
      <h3 className="title">{data.frontmatter.title}</h3>
    </Link>
    <DateCategories data={data.frontmatter} />
    {data.frontmatter.featuredImage ? (
      <Link
        className="link-wrapper"
        to={blogPostURLProcess(data.fields.slug)}
        aria-label={`Read more about ${data.frontmatter.title}`}
      >
        <Img fluid={data.frontmatter.featuredImage.childImageSharp.fluid} loading="eager" fadeIn={false} />
      </Link>
    ) : null}
    <Link
      className="link-wrapper"
      to={blogPostURLProcess(data.fields.slug)}
      aria-label={`Read more about ${data.frontmatter.title}`}
    >
      <p className="excerpt">{data.frontmatter.excerpt}</p>
    </Link>
  </article>
);

export default PostPreview;
